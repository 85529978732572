var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cw-page',{staticClass:"cw-business-loan-signees",attrs:{"icon":"border_color"},scopedSlots:_vm._u([{key:"hero-title",fn:function(){return [_c('translate',[_vm._v(" The signers of the loan application ")])]},proxy:true},{key:"default",fn:function(){return [_c('signees',_vm._b({},'signees',{
        form: _vm.form,
        onRemoveItem: _vm.onRemoveItem,
        validated: _vm.validated,
      },false)),_c('owners',_vm._b({staticClass:"mt-6"},'owners',{
        form: _vm.form,
        onRemoveItem: _vm.onRemoveItem,
        validated: _vm.validated,
      },false)),_c('signees-dialog')]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"id":"submit","dark":!_vm.$wait.waiting('SUBMIT_FORM'),"disabled":_vm.$wait.waiting('SUBMIT_FORM'),"loading":_vm.$wait.waiting('SUBMIT_FORM'),"color":"purple-one darken-2"},on:{"click":function($event){_vm.validateForm();
        _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v("Continue")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }